/**
 * Splide slider for top page
 */
// @ts-nocheck
import Splide from '@splidejs/splide';

 const splideTop = () => {
    new Splide('.top__company-slider').mount();
    new Splide('.top__rec-slider', {
      destroy: true,
      breakpoints: {
        767: {
          destroy: false,
          type: 'loop',
        },
      }
    }).mount();
}

export default splideTop
